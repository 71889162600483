<template>
  <section>
    <land-banner
      :items="banItems"
      :height="classify.height"
      :points="classify.points"
      :cycle="classify.cycle"
      :interval="classify.interval"
      :hide-delimiters="wdHideDelimiters"
      :show-arrows="wdShowArrows"
    />

    <scheme-notice />
    <scheme-policy />
    <scheme-disclose />

    <land-contact-way
      dark
      back-color="blue-grey darken-4"
    />
  </section>
</template>

<script>
  import mixHomeBanner from '@/pages/mixins/home/mix.home.banner'

  export default {
    metaInfo: { title: '首页' },
    components: {
      SchemeNotice: () => import('@/pages/sections/SchemeNotice.vue'),
      SchemePolicy: () => import('@/pages/sections/SchemePolicy.vue'),
      SchemeDisclose: () => import('@/pages/sections/SchemeDisclose.vue')
    },
    mixins: [
      mixHomeBanner,
    ],
    data () {
      return {
      }
    },
    created () {
      this.initBannerParams({
        topicId: this.banTopicId,
        categoryId: this.banCategoryId
      })
      this.loadBanners()
    },
    methods: {
    }
  }
</script>

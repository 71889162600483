
import mixBannerBase from '@/pages/mixins/mix.banner.base'

export default {
  mixins: [mixBannerBase],
  data () {
    return {
    }
  },
  created () {
    this.banTopicId = ''
    this.classify.height = 460
    Object.assign(this.classify.points, {
      xs: 200,
      sm: 300,
      md: 460,
      lg: 460,
      xl: 460
    })
  },
  methods: {
  }
}
